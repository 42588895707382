import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biaya',
  templateUrl: './biaya.component.html',
  styleUrls: ['../style-onboarding.scss']
})
export class BiayaComponent implements OnInit {

  windowScrolled = false;

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });
  }

  scrollToTop() {
    document.getElementById("first-div").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

}
