import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ForgetPasswordComponent } from './views/forget-password/forget-password.component';
import { HomeComponent } from './views/onboarding/home/home.component';
import { SolusiComponent } from './views/onboarding/solusi/solusi.component';
import { IndustriComponent } from './views/onboarding/industri/industri.component';
import { BiayaComponent } from './views/onboarding/biaya/biaya.component';
import { PrivasiComponent } from './views/onboarding/privasi/privasi.component';
import { DaftarComponent } from './views/onboarding/daftar/daftar.component';
import { KontakComponent } from './views/onboarding/kontak/kontak.component';
import { DepositComponent } from './views/deposit/deposit.component';
import { UpgradeComponent } from './views/upgrades/upgrade/upgrade.component';
import { PremiumComponent } from './views/upgrades/premium/premium.component';

export const routes: Routes = [
  {
    path: '',
    //redirectTo: 'dashboard',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Profil'
    }
  },
  {
    path: 'solusi',
    component: SolusiComponent,
    data: {
      title: 'Profil'
    }
  },
  {
    path: 'industri',
    component: IndustriComponent,
    data: {
      title: 'Profil'
    }
  },
  {
    path: 'biaya',
    component: BiayaComponent,
    data: {
      title: 'Profil'
    }
  },
  {
    path: 'kontak',
    component: KontakComponent,
    data: {
      title: 'Profil'
    }
  },
  {
    path: 'privasi',
    component: PrivasiComponent,
    data: {
      title: 'Profil'
    }
  },
  {
    path: 'daftar',
    component: DaftarComponent,
    data: {
      title: 'Profil'
    }
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgetPassword',
    component: ForgetPasswordComponent,
    data: {
      title: 'Forget Password Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'deposit',
    component: DepositComponent,
    data: {
      title: 'Deposit Page'
    }
  },
  {
    path: 'upgrade',
    component: UpgradeComponent,
    data: {
      title: 'Upgrade Premium Page'
    }
  },
  {
    path: 'premium',
    component: PremiumComponent,
    data: {
      title: 'Premium Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'absensi',
        loadChildren: () => import('./views/absensi/absensi.module').then(m => m.AbsensiModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'master',
        loadChildren: () => import('./views/master/master.module').then(m => m.MasterModule),
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
