import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  defaultHeader: HttpHeaders;

  constructor(
    private http: HttpClient, 
  ) { 
    this.defaultHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  Login(data) {
    return this.http.post(environment.apiEndPoint +`/AGeneral/Login`, data);
  }

  Register(data) {
    return this.http.post(environment.apiEndPoint +`/AGeneral/Register`, data);
  }

  Question(data) {
    return this.http.post(environment.apiEndPoint +`/AGeneral/Question`, data);
  }

  QuestionSearch(data) {
    return this.http.post(environment.apiEndPoint +`/AGeneral/QuestionSearch`, data);
  }

  ForgetPassword(email){
    return this.http.get(environment.apiEndPoint +`/AGeneral/ForgetPassword/${email}`);
  }

}


