import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-onboarding',
  templateUrl: './modal-onboarding.component.html',
  styleUrls: ['./modal-onboarding.component.scss']
})
export class ModalOnboardingComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalOnboardingComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private router:Router,) { 
  }

  ngOnInit(): void {
  }

  ok(){
    this.router.navigate(['/home'])
  }
}
