import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-home'
  },
  {
    name: 'Master Divisi',
    url: '/master/divisi',
    icon: 'cil-fork'
  },
  {
    name: 'Master Lokasi',
    url: '/master/lokasi',
    icon: 'cil-location-pin'
  },
  {
    name: 'Lokasi Kantor',
    url: '/master/lokasi-kantor',
    icon: 'cil-building'
  },
  {
    name: 'Data Karyawan',
    url: '/master/karyawan',
    icon: 'cil-group'
  },
  {
    name: 'Absensi',
    url: '/absensi',
    icon: 'cil-calendar-check',
    children: [
      {
        name: 'Absensi Per Karyawan',
        url: '/absensi/per-karyawan'
      },
      {
        name: 'Absensi Per Bulan',
        url: '/absensi/per-bulan'
      },
      {
        name: 'Data Absensi',
        url: '/absensi/data-absensi'
      },
      {
        name: 'History Absensi',
        url: '/absensi/history-absensi'
      },
      {
        name: 'Approval',
        url: '/absensi/approval'
      }
    ]
  },
];
