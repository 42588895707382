import { Component } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { LoginModel, ResultLogin } from './loginModels';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../service/token.service';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GlobalFunctionService } from '../../service/global-function.service';
import * as CryptoJS from 'crypto-js';  

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent { 

  public loginModel : LoginModel;
  public resultLogin : ResultLogin;
  isEmail: boolean = true;
  isPassword: boolean = true;
  messageLogin: string = "";
  showPassword: boolean = true;
  isRemember: boolean = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private tokenStorageService : TokenStorageService,
    public modalInfo: MatDialog,
    private globalFunctionService : GlobalFunctionService
    ) { 

    this.loginModel = new LoginModel();
    this.loginModel.email = '';
    this.loginModel.password = '';

    this.resultLogin = new ResultLogin();
    this.resultLogin.success = true;
    }

  ngOnInit(): void {
    console.log('init')

    // var tesss = CryptoJS.AES.encrypt("3PM-Admin","CBoaDQIQAgceGg8dFAkMDBEOECEZCxgMBiAUFQwKFhg=").toString();
    // console.log('tes encrypt', tesss)

    // var decc = CryptoJS.AES.decrypt(tesss,"CBoaDQIQAgceGg8dFAkMDBEOECEZCxgMBiAUFQwKFhg=").toString(CryptoJS.enc.Utf8);
    // console.log('decc ',decc)

    const email = this.tokenStorageService.getEmail();
    console.log('email : ', email);
    const password = this.tokenStorageService.getPassword();
    console.log('password : ', password);

    if(email != null && password != null){
      this.isRemember = true
      this.loginModel.email = email
      var decryptedPassword = CryptoJS.AES.decrypt(password,"CBoaDQIQAgceGg8dFAkMDBEOECEZCxgMBiAUFQwKFhg=").toString(CryptoJS.enc.Utf8);
      this.loginModel.password = decryptedPassword
    }else{
      this.isRemember = false
    }

    console.log('init remember : ', this.isRemember)

  }

  rememberChanged(){
    this.isRemember = !this.isRemember
    console.log('remember ?? ', this.isRemember);
  }

  login()
  {  
    const email = this.tokenStorageService.getEmail();
    const password = this.tokenStorageService.getPassword();

    if(email != null && password != null){
      console.log('login email & password ADA')
      if(this.loginModel.email == email){
        console.log('email sama')
        console.log('email sama remember', this.isRemember)
        if(!this.isRemember){
          console.log('delete remember')
          this.tokenStorageService.deleteRememberUser()
        }
      }
    }

    this.isEmail = true;
    this.isPassword = true;
    
    const data = {
      email: this.loginModel.email,
      password: this.loginModel.password
    };
    //console.log(data)

    if(this.loginModel.email == "" || this.loginModel.password == "")
    {
      if(this.loginModel.email == "")
      {
        this.isEmail = false
      }
      if(this.loginModel.password == "")
      {
        this.isPassword = false
      }
    }
    else
    {
      this.loginService.Login(data).subscribe(response => {
        console.log(response);

        this.resultLogin.success = response['success'];
        if (this.resultLogin.success)
        {
          if(response['role'] == "Admin"){

            console.log('isRemember checked: ',this.isRemember)
            if(this.isRemember){
              console.log('remember')
              this.tokenStorageService.saveEmail(this.loginModel.email);
              var encryptedPassword = CryptoJS.AES.encrypt(this.loginModel.password,"CBoaDQIQAgceGg8dFAkMDBEOECEZCxgMBiAUFQwKFhg=").toString();
              this.tokenStorageService.savePassword(encryptedPassword);
            }else{
              console.log('NOT remember')
            }

            this.messageLogin = ""
            this.tokenStorageService.saveToken(response['accessToken']);
            this.tokenStorageService.saveRefreshToken(response['refreshToken']);
            this.tokenStorageService.saveUser(response);
  
            this.router.navigate(['/dashboard']);
          }else{
            this.globalFunctionService.dialogError("Tidak dapat mengakses halaman web admin")
          }
        }
        else
        {
          this.messageLogin = response['message']

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = "validai-login";
          dialogConfig.height = "auto";
          dialogConfig.width = "auto";
          dialogConfig.data = {
            description: this.messageLogin,
            confirmButtonText: "Close",
          };
          const modalDialog = this.modalInfo.open(ModalInfoComponent, dialogConfig);
        }
      });
    }

  }

  tooglePassword()
  {
    console.log('tooglePassword')
    if(this.showPassword == true)
    {
      this.showPassword = false
    }
    else
    {
      this.showPassword = true
    }
    console.log('showPassword',this.showPassword)
  }

}
