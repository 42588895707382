import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class MidtransService {

  constructor(
    private http: HttpClient,
    //private httpHeaders: HttpHeaders,
  ) { 
    // let header = new HttpHeaders()
    // header.append('Accept','application/json');
    // header.append('Content-Type','application/json');
    // header.append('Authorization','Basic U0ItTWlkLXNlcnZlci1JX3hGM3NNdC10ZnljekhjdnNEaGhXbjM=');
  }

  generateTransaction(data){
    // let header = new HttpHeaders()
    // header.append('Accept','application/json');
    // header.append('Content-Type','application/json');
    // header.append('Authorization','Basic U0ItTWlkLXNlcnZlci1JX3hGM3NNdC10ZnljekhjdnNEaGhXbjM=');

    //console.log('url', environment.apiMidtrans +`/snap/v1/transactions`)
    // return this.http.post(`https://app.sandbox.midtrans.com/snap/v1/transactions`, data
    return this.http.post(environment.apiEndPoint+`/Midtrans/generateTransactions`, data 
    //,{headers: header}

    // ,{ headers: new HttpHeaders({
    //   'Accept' : 'application/json',
    //   'Content-Type' : 'application/json',
    //   'Authorization': 'Basic U0ItTWlkLXNlcnZlci1JX3hGM3NNdC10ZnljekhjdnNEaGhXbjM='
    //   })
    // }
    );
  }

}
