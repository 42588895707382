import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';
const EMAIL = 'email';
const PASSWORD = 'password';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    //window.localStorage.clear();
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESHTOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
  }

  deleteRememberUser(): void{
    window.localStorage.removeItem(EMAIL);
    window.localStorage.removeItem(PASSWORD);
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);

    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, accessToken: token });
    }
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    window.localStorage.removeItem(REFRESHTOKEN_KEY);
    window.localStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.localStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public saveEmail(email: string): void {
    window.localStorage.removeItem(EMAIL);
    window.localStorage.setItem(EMAIL, email);
  }

  public getEmail(): string | null {
    return window.localStorage.getItem(EMAIL);
  }

  public savePassword(password: string): void {
    window.localStorage.removeItem(PASSWORD);
    window.localStorage.setItem(PASSWORD, password);
  }

  public getPassword(): string | null {
    return window.localStorage.getItem(PASSWORD);
  }

}
