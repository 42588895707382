import { Component, Input, OnInit, } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { DomSanitizer } from '@angular/platform-browser';
import { UserTokenService } from '../../service/user-token.service';
import { MidtransService } from '../../service/midtrans.service';
import { GlobalFunctionService } from '../../service/global-function.service';
import { TokenStorageService } from '../../service/token.service';
import { CompanyService } from '../../service/company.service';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {

  public sidebarMinimized = false;
  public navItems = navItems;
  dataEmployee:any=[];
  dataCompany:any;
  namePage: string = "Dashboard";
  public imageName: any;
  isMinimized= false;
  public screenWidth = window.innerWidth;
  depositTotal: number = null;
  isDeposit: boolean = true;
  isFormatDeposit: boolean = true;
  historyTrans:any=[];

  constructor(
    private userTokenService : UserTokenService,
    private sanitizer: DomSanitizer,
    private midtransService : MidtransService,
    private globalFunctionService: GlobalFunctionService,
    private tokenStorageService : TokenStorageService,
    private companyService : CompanyService,
    public datepipe: DatePipe,
    private router: Router
  ) { }

  @Input() options: string[] = [
    '100.000',
    '200.000',
    '300.000',
    '400.000',
    '500.000'
  ];

  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
    console.log('chip',chip)
    console.log('chip.value',chip.value)
    this.depositTotal = chip.value.replace(".","")
    console.log('this.depositTotal',this.depositTotal)
    this.depositChanged()
  }

  ngOnInit(): void {
    this.userTokenService.Employee().subscribe(user => {
      this.dataEmployee = user
      console.log('dataEmployee', this.dataEmployee)

      this.companyService.companyById(user['companyId']).subscribe(company => {
        this.dataCompany = company
      })

      this.companyService.historyTransaction(user['companyId']).subscribe(history => {
        this.historyTrans = history
      })

      if(this.dataEmployee.photo != ""){
        this.userTokenService.GetFileMaster(this.dataEmployee.photo).subscribe(image => {
          console.log('response img', image)

          const urlCreator = window.URL;
          console.log('urlCreator INIT', urlCreator)

          this.imageName = this.sanitizer.bypassSecurityTrustUrl(
            urlCreator.createObjectURL(image)
          );

          console.log('imageName >>>', this.imageName)
          console.log('urlCreator >>>', urlCreator)

        })
      }

    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  depositChanged(){
    console.log('this.depositTotal CHANGE',this.depositTotal)
    if(this.depositTotal != null)
    {
      this.isDeposit = true

      var isNumber = Number(this.depositTotal)
      console.log('isNumber',isNumber)
      !isNumber ? this.isFormatDeposit = false : this.isFormatDeposit = true
    }
    else
    {
      this.isDeposit = false
      this.isFormatDeposit = true
    }
  }

  generateOrderId(){
    var jenis = "DEV-WP"
    var newDate = new Date();
    var date = this.datepipe.transform(newDate, 'yyyyMMdd');
    var time = this.datepipe.transform(newDate, 'HHmmssSSS');
    var random = Math.floor(1000 + Math.random() * 9999)
    var orderID = jenis + "-" + date + "-" + time + "-" + random

    return orderID
  }

  next(){
    if(this.depositTotal >= 100000){
      this.userTokenService.Employee().subscribe(user => {
        console.log('userToken',user)
  
        var orderID = this.generateOrderId()
        
        var first_name = user['fullname'] ? user['fullname'] : ""
        var email = user['email'] ? user['email'] : ""
        var phone = user['phonenumber'] ? user['phonenumber'] : ""
        var address = user['address'] ? user['address'] : ""
  
        const data = {
          transaction_details: {
            order_id: orderID,
            gross_amount: Number(this.depositTotal)
          },
          customer_details: {
            first_name: first_name,
            email: email,
            phone: phone,
            billing_address: {
              address: address,
            }
          }
        };
  
        console.log('param generate trans',data)
  
        this.midtransService.generateTransaction(data).subscribe(res => {
          console.log('res midtrans', res)
          console.log('res midtrans status', res['status'])
          var status = res['status']
          if(status.toLowerCase().includes('success'))
          {   
            const dataDeposit = {
              orderId: orderID,
              companyId: user['companyId'] ? user['companyId'] : 0,
              userId: user['id'] ? user['id'] : 0,
              email: user['email'] ? user['email'] : "",
              paymentType: null,
              debit: Number(this.depositTotal),
              status: "PENDING",
              info: "Top Up"
            }
            this.companyService.deposit(dataDeposit).subscribe(res => {
              console.log('res deposit', res)
              var status = res['status']
              if(status.toLowerCase().includes('success'))
              {   
                console.log('sukses update deposit')
              }
              else
              {
                var message = res['message']
                console.log('gagal update deposit', message)
                this.globalFunctionService.dialogError('gagal update deposit, hubungi contact support');
              }
            },
            (error) => {
              console.log('error update deposit', error)
            });
            
            //this.globalFunctionService.dialogSuccess('Sukses tambah divisi',true)

            var url = res['redirect_url']
            console.log('redirect url',url)
            window.open(url, "_blank");
          }
          else
          {
            var message = res['message']
            this.globalFunctionService.dialogError(message);
          }
        },
        (error) => {
          console.log('error generate midtrans transaction ', error)
          this.globalFunctionService.dialogError(error.message)
        });
        
      });
    }else{
      this.globalFunctionService.dialogError("Minimal deposit Rp 100.000")
    }
  }

  logout(){
    this.tokenStorageService.signOut()
    this.router.navigate(['/login']);
  }

}
