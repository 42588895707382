import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
  ) { }

  companyById(id){
    return this.http.get(environment.apiEndPoint +`/Company/${id}`);
  }

  deposit(data) {
    // return this.http.post('https://localhost:44392/api' +`/Company/Deposit`, data);
    return this.http.post(environment.apiEndPoint +`/Company/Deposit`, data);
  }

  cashOut(data) {
    //return this.http.post('https://localhost:44392/api' +`/Company/CashOut`, data);
    return this.http.post(environment.apiEndPoint +`/Company/CashOut`, data);
  }

  historyTransaction(id){
    return this.http.get(environment.apiEndPoint +`/Company/HistoryTrans${id}`);
  }

}
