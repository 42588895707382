// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiEndPoint: 'https://149.28.155.78:60773/api',
  apiEndPoint: 'https://workpres.my.id:60775/api',
  apiMidtrans: 'https://app.sandbox.midtrans.com', //sanbox
  //apiMidtrans: '', //prod
};
