import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privasi',
  templateUrl: './privasi.component.html',
  styleUrls: ['../style-onboarding.scss']
})
export class PrivasiComponent implements OnInit {

  constructor() { }

  isSetuju = true;
  lanjutkanPrivasi() {
      this.isSetuju = !this.isSetuju;
      return;
  }

  ngOnInit(): void {
  }

}
