import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LokasiKantorService {

  constructor(
    private http: HttpClient,
  ) { }

  AllLokasiKantor() {
    return this.http.get(environment.apiEndPoint +`/Office`);
  }

  ActiveLokasiKantor() {
    return this.http.get(environment.apiEndPoint +`/Office/ListActive`);
  }

  PostLokasiKantor(data) {
    return this.http.post(environment.apiEndPoint +`/Office`, data);
  }

  UpdateLokasiKantor(id , data) {
    return this.http.put(environment.apiEndPoint +`/Office/${id}`, data);
  }

  LokasiKantorById(id){
    return this.http.get(environment.apiEndPoint +`/Office/${id}`);
  }

  DeleteLokasiKantor(id){
    return this.http.delete(environment.apiEndPoint +`/Office/${id}`);
  }

  SearchLokasiKantor(data) {
    return this.http.post(environment.apiEndPoint +`/Office/Search`, data);
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

}
