import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs'; 
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 

import { authInterceptorProviders } from './helper/auth.interceptor';
import { AgmCoreModule } from '@agm/core';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { ApiService } from './service/api.service';
import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginModalComponent } from './views/login-modal/login-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
//import { ProfileComponent } from './views/profile/profile.component';
import { ForgetPasswordComponent } from './views/forget-password/forget-password.component';
import { DeleteModalComponent } from './views/master/delete-modal/delete-modal.component';
import { HomeComponent } from './views/onboarding/home/home.component';
import { SolusiComponent } from './views/onboarding/solusi/solusi.component';
import { IndustriComponent } from './views/onboarding/industri/industri.component';
import { BiayaComponent } from './views/onboarding/biaya/biaya.component';
import { PrivasiComponent } from './views/onboarding/privasi/privasi.component';
import { DaftarComponent } from './views/onboarding/daftar/daftar.component';
import { ModalOnboardingComponent } from './views/onboarding/modal-onboarding/modal-onboarding.component';
import { ModalInfoComponent } from './views/modal-info/modal-info.component';
import { ModalSuccessComponent } from './views/modal-success/modal-success.component';
import { KontakComponent } from './views/onboarding/kontak/kontak.component';
import { DepositComponent } from './views/deposit/deposit.component';
import { MatChipsModule } from '@angular/material/chips'; 
import { DatePipe } from '@angular/common';
import { UpgradeComponent } from './views/upgrades/upgrade/upgrade.component';
import { PremiumComponent } from './views/upgrades/premium/premium.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    ModalModule.forRoot(),
    MatDialogModule,
    MatTabsModule,
    HttpClientModule,
    MatChipsModule,
    FormsModule,
    MatProgressSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey : 'AIzaSyBdLJL3rfB5clC4efik97WPcFbYKHllFnI'
    })
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    LoginModalComponent,
    //ProfileComponent,
    ForgetPasswordComponent,
    DeleteModalComponent,
    HomeComponent,
    SolusiComponent,
    IndustriComponent,
    BiayaComponent,
    PrivasiComponent,
    DaftarComponent,
    ModalOnboardingComponent,
    ModalInfoComponent,
    ModalSuccessComponent,
    KontakComponent,
    DepositComponent,
    UpgradeComponent,
    PremiumComponent
    // MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    ApiService,
    IconSetService,
    authInterceptorProviders,
    DatePipe
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
