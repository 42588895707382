import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class KaryawanService {

  constructor(
    private http: HttpClient,
  ) { }

  AllKaryawan() {
    return this.http.get(environment.apiEndPoint +`/Employee`);
  }

  Role() {
    return this.http.get(environment.apiEndPoint +`/Employee/EmployeeRole`);
  }

  PostKaryawan(data) {
    return this.http.post(environment.apiEndPoint +`/Employee`, data);
  }

  UpdateKaryawan(id , data) {
    return this.http.put(environment.apiEndPoint +`/Employee/${id}`, data);
  }

  KaryawanById(id){
    return this.http.get(environment.apiEndPoint +`/Employee/${id}`);
  }

  DeleteKaryawan(id){
    return this.http.delete(environment.apiEndPoint +`/Employee/${id}`);
  }

  SearchKaryawan(data) {
    return this.http.post(environment.apiEndPoint +`/Employee/Search`, data);
  }

}
