import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  email: string = "";
  isEmail: boolean = true;
  isSuccess: boolean = true;
  errorMessage: string = "";
  successMassage: string = "";
  showSuccessMassage: boolean = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
  }

  resetPassword()
  {
    this.isEmail = true;
    this.isSuccess = true;
    this.errorMessage = "";
    this.successMassage = "";
    this.showSuccessMassage = false;

    if(this.email == "")
    {
      this.isEmail = false
    }
    else
    {      
      this.loginService.ForgetPassword(this.email).subscribe(response => {
        console.log(response);
          if(response['status'] == "Success Reset Password")
          {
            this.isSuccess = true
            this.showSuccessMassage = true
            this.successMassage = response['status'] + ", please check your email to find out the new password."
          }
          else
          {
            this.isSuccess = false;
            this.errorMessage = response['message']
          }
      });

    }
  }
}
