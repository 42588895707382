import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { navItems } from '../../_nav';
import { UserTokenService } from '../../service/user-token.service';
import { LokasiKantorService } from '../../service/lokasi-kantor.service';
import { TokenStorageService } from '../../service/token.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent implements OnInit, AfterViewInit{

  public sidebarMinimized = false;
  public navItems = navItems;
  dataEmployee:any=[];
  namePage: string = "Dashboard";
  public imageName: any;
  isMinimized= false;
  public screenWidth = window.innerWidth

  constructor(
    private userTokenService : UserTokenService,
    private lokasiKantorService : LokasiKantorService,
    private tokenStorageService : TokenStorageService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) { }

  ngAfterViewInit(): void {
    var width = this.myIdentifier.nativeElement.offsetWidth;
    console.log('Width:' + width);
  }

  @ViewChild('myIdentifier', {read: ElementRef, static:false}) myIdentifier: ElementRef; viewHeight: number;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
    console.log('sidebarMinimized', e)
  }

  ngOnInit(): void {

    var url = location.hash.replace("#","")

    var getPageName = navItems.filter(function(item){
      return item.url === url
    })
    if(getPageName.length > 0)
    {
      var arr = [] 
      arr = getPageName
      this.namePage = arr[0].name
    }
    else
    {
      // for(var i=0; i< navItems.length; i++)
      // {
      //   if(navItems[i].children)
      //   {
          var getChildPageName = navItems[5].children.filter(function(item){
            return item.url === url
          })
          if(getChildPageName.length > 0)
          {
            var arrChild = [] 
            arrChild = getChildPageName
            this.namePage = arrChild[0].name
          }
          else
          {
            this.namePage = "Dashboard"
          }
        }
    //   }
    // }

    this.userTokenService.Employee().subscribe(user => {
      this.dataEmployee = user
      //console.log('dataEmployee', this.dataEmployee)

      if(this.dataEmployee.photo != ""){
        this.userTokenService.GetFileMaster(this.dataEmployee.photo).subscribe(image => {
          //console.log('response img', image)

          const urlCreator = window.URL;
          //console.log('urlCreator INIT', urlCreator)

          this.imageName = this.sanitizer.bypassSecurityTrustUrl(
            urlCreator.createObjectURL(image)
          );

          //console.log('imageName >>>', this.imageName)
          //console.log('urlCreator >>>', urlCreator)

        })
      }

    });
  }

  setNamePage()
  {
    var url = location.hash.replace("#","")
    console.log('url',url)

    var getPageName = navItems.filter(function(item){
      return item.url === url
    })
    if(getPageName.length > 0)
    {
      var arr = [] 
      arr = getPageName
      this.namePage = arr[0].name
    }
    else
    {
      // for(var i=0; i< navItems.length; i++)
      // {
      //   if(navItems[i].children)
      //   {
          var getChildPageName = navItems[5].children.filter(function(item){
            return item.url === url
          })
          if(getChildPageName.length > 0)
          {
            var arrChild = [] 
            arrChild = getChildPageName
            this.namePage = arrChild[0].name
          }
          else
          {
            this.namePage = "Dashboard"
          }
      //   }
      // }      
    }
  }

  logout(){
    this.tokenStorageService.signOut()
    this.router.navigate(['/login']);
  } 

}
