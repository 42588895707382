import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { GlobalFunctionService } from '../../../service/global-function.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../style-onboarding.scss']
})
export class HomeComponent implements OnInit {

  windowScrolled = false;
  namaLengkap : string = "";
  email : string = "";
  pesan : string = "";
  isNamaLengkap : boolean = true;
  isEmail : boolean = true;
  isFormatEmail : boolean = true;
  isPesan : boolean = true;

  constructor(
    private loginService : LoginService,
    private globalFunctionService : GlobalFunctionService
  ) { }

  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });


    const data = {
      companyId: 0,
      keyword: "",
      active: "",
      pageSize: 1000,
      pageNumber: 1
    }
    //console.log('param : ', data)
    this.loginService.QuestionSearch(data).subscribe(res => {
      console.log('list question : ' ,res)
     
        // var message = res['message']
        // console.log('message : ', message)
  
    },
    (error) => {
      console.log('ERROR create : ', error)
    });


  }

  namaLengkapChanged()
  {
    this.namaLengkap == "" ? this.isNamaLengkap = false : this.isNamaLengkap = true
  }

  emailChanged()
  {
    if(this.email != "")
    {
      this.isEmail = true
      this.email.includes("@") && this.email.includes(".") ? this.isFormatEmail = true : this.isFormatEmail = false
    }
    else
    {
      this.isEmail = false
      this.isFormatEmail = true
    }
  }

  pesanChanged()
  {
    this.pesan == "" ? this.isPesan = false : this.isPesan = true
  }

  kirim(){
    if(this.namaLengkap == "" || this.pesan == "" || this.email == "" || !this.email.includes("@") || !this.email.includes(".")){
      if(this.namaLengkap == "" || this.pesan == "" || this.email == ""){
        this.namaLengkapChanged()
        this.pesanChanged()
        this.emailChanged()
      }
    }else{
      const data = {
        id: 0,
        name : this.namaLengkap,
        email: this.email,
        message: this.pesan,
        dateCreated: new Date()
      }      
      console.log('param : ', data)
      this.loginService.Question(data).subscribe(res => {
        var status = res['status']
        if(status.toLowerCase().includes('success'))
        {
          this.namaLengkap = "";
          this.email = "";
          this.pesan = "";
          this.globalFunctionService.dialogSuccess('Pesan Terkirim!',false)
        }
        else
        {
          var message = res['message']
          this.globalFunctionService.dialogError(message)
        }
      },
      (error) => {
        console.log('ERROR create : ', error)
        //this.globalFunctionService.errorDialog('Error Login',error.message)
      });
    }
  }
  
  scrollToFitur() {
    document.getElementById("fitur").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scrollToTop() {
    document.getElementById("first-div").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

}
