import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalFunctionService } from '../../../service/global-function.service';
import { TokenStorageService } from '../../../service/token.service';
import { MidtransService } from '../../../service/midtrans.service';
import { UserTokenService } from '../../../service/user-token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {

  public sidebarMinimized = false;
  dataEmployee:any=[];
  namePage: string = "Dashboard";
  public imageName: any;
  isMinimized= false;
  public screenWidth = window.innerWidth

  constructor(private userTokenService : UserTokenService,
    private sanitizer: DomSanitizer,
    private midtransService : MidtransService,
    private globalFunctionService : GlobalFunctionService,
    private tokenStorageService : TokenStorageService,
    public datepipe: DatePipe,
    private router: Router) { }

  ngOnInit(): void {
    this.userTokenService.Employee().subscribe(user => {
      this.dataEmployee = user
      console.log('dataEmployee', this.dataEmployee)

      if(this.dataEmployee.photo != ""){
        this.userTokenService.GetFileMaster(this.dataEmployee.photo).subscribe(image => {
          console.log('response img', image)

          const urlCreator = window.URL;
          console.log('urlCreator INIT', urlCreator)

          this.imageName = this.sanitizer.bypassSecurityTrustUrl(
            urlCreator.createObjectURL(image)
          );

          console.log('imageName >>>', this.imageName)
          console.log('urlCreator >>>', urlCreator)

        })
      }

    });
  }

  logout(){
    this.tokenStorageService.signOut()
    this.router.navigate(['/login']);
  }

}
