export class LoginModel{
    email : string = "";
    password : string = "";
}

export class ResultLogin{
    message : string;
    success : boolean;
    userid : number;
    userName : string;
    role : string;
    accessToken : string;
    refreshToken : string;
}