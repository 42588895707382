import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalFunctionService } from '../../../service/global-function.service';
import { MidtransService } from '../../../service/midtrans.service';
import { UserTokenService } from '../../../service/user-token.service';
import { TokenStorageService } from '../../../service/token.service';
import { CompanyService } from '../../../service/company.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss']
})
export class PremiumComponent implements OnInit {

  public sidebarMinimized = false;
  dataEmployee:any=[];
  namePage: string = "Dashboard";
  public imageName: any;
  isMinimized= false;
  companyId: number = 0;
  karyawanTotal: number = null;
  isKaryawan: boolean = true;
  isFormatKaryawan: boolean = true;
  hargaPerKaryawan: number = 0;
  totalPerBulan: number = 0;
  totalTagihan: number = 0;
  periode: number = 1;
  periodeString: string = "1 bulan";
  debitCompany: number = 0;
  public screenWidth = window.innerWidth

  constructor(private userTokenService : UserTokenService,
    private sanitizer: DomSanitizer,
    private midtransService : MidtransService,
    private globalFunctionService: GlobalFunctionService,
    private tokenStorageService : TokenStorageService,
    private companyService : CompanyService,
    public datepipe: DatePipe,
    private router: Router) 
  { }

  @Input() options: string[] = [
    '20',
    '50',
    '75',
    '100',
    '200'
  ];
  
  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
    this.karyawanTotal = chip.value.replace(".","")
    this.karyawanChanged()
    this.calculateTagihan()
  }

  ngOnInit(): void {
    this.userTokenService.Employee().subscribe(user => {
      this.dataEmployee = user
      this.companyId = user['companyId']
      console.log('dataEmployee', this.dataEmployee)

      if(this.dataEmployee.photo != ""){
        this.userTokenService.GetFileMaster(this.dataEmployee.photo).subscribe(image => {
          console.log('response img', image)

          const urlCreator = window.URL;
          console.log('urlCreator INIT', urlCreator)

          this.imageName = this.sanitizer.bypassSecurityTrustUrl(
            urlCreator.createObjectURL(image)
          );

          console.log('imageName >>>', this.imageName)
          console.log('urlCreator >>>', urlCreator)

        })
      }

    });
  }

  logout(){
    this.tokenStorageService.signOut()
    this.router.navigate(['/login']);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  karyawanChanged(){
    if(this.karyawanTotal != null)
    {
      this.isKaryawan = true

      var isNumber = Number(this.karyawanTotal)
      !isNumber ? this.isFormatKaryawan = false : this.isFormatKaryawan = true
    }
    else
    {
      this.isKaryawan = false
      this.isFormatKaryawan = true
    }
    console.log('this.isFormatKaryawan',this.isFormatKaryawan)
    this.calculateTagihan()
  }

  periodeChanged(){
    if(this.periode == 12){
      this.periodeString = "1 tahun"
    }else{
      this.periodeString = this.periode + " bulan"
    }
    this.calculateTagihan()
  }

  pesan(){
    console.log('next', this.karyawanTotal)
    if(this.karyawanTotal >= 6){
      this.companyService.companyById(this.companyId).subscribe(company => {
        this.debitCompany = company['balance']
        
        if(this.debitCompany >= this.totalTagihan){
          const dataCredit = {
            companyId: this.dataEmployee.companyId,
            userId: this.dataEmployee.id,
            email: this.dataEmployee.email,
            credit: this.totalTagihan,
            info: "Tagihan Layanan",
            Status: "SUCCESS"
          }
          this.companyService.cashOut(dataCredit).subscribe(res => {
            var status = res['status']
            if(status.toLowerCase().includes('success'))
            {
              this.globalFunctionService.dialogSuccess('Pembelian berhasil',true)
            }
            else
            {
              var message = res['message']
              this.globalFunctionService.dialogError(message);
            }
          });
        }else{
          this.globalFunctionService.dialogError("Saldo tidak cukup, silahkan isi deposit terlebih dahulu")
          //this.router.navigate(['deposit'],{skipLocationChange: true})
        }

      });
    }else{
      this.globalFunctionService.dialogError("Minimal 6 karyawan")
    }
  }

  calculateTagihan(){
    if(this.karyawanTotal >= 6){
      if(this.karyawanTotal <= 25){
        this.hargaPerKaryawan = 6000
      }else if(this.karyawanTotal <= 50){
        this.hargaPerKaryawan = 5500
      }else if(this.karyawanTotal <= 100){
        this.hargaPerKaryawan = 5000
      }else{
        this.hargaPerKaryawan = 4500
      }
      this.totalPerBulan = this.hargaPerKaryawan * this.karyawanTotal
      this.totalTagihan = this.hargaPerKaryawan * this.karyawanTotal * this.periode
    }else{
      this.hargaPerKaryawan = 0
      this.totalPerBulan = 0
      this.totalTagihan = 0
    }
  }

}
