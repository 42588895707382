import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { TokenStorageService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class DivisiService {

  defaultHeader: HttpHeaders;

  constructor(
    private http: HttpClient, 
    private tokenStorageService : TokenStorageService,
  ) {
    this.defaultHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization' : 'Bearer ' + tokenStorageService.getRefreshToken()
      });
    }

  AllDivisi() {
    return this.http.get(environment.apiEndPoint +`/Division`);
  }

  ActiveDivisi() {
    return this.http.get(environment.apiEndPoint +`/Division/ListActive`);
  }

  PostDivisi(data) {
    return this.http.post(environment.apiEndPoint +`/Division`, data);
  }

  UpdateDivisi(id , data) {
    return this.http.put(environment.apiEndPoint +`/Division/${id}`, data);
  }

  DivisiById(id){
    return this.http.get(environment.apiEndPoint +`/Division/${id}`);
  }

  DeleteDivisi(id){
    return this.http.delete(environment.apiEndPoint +`/Division/${id}`);
  }

  SearchDivisi(data) {
    return this.http.post(environment.apiEndPoint +`/Division/Search`, data);
  }

}
