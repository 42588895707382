import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log('this.modalData INFO',this.modalData)
  }

  ok(){
    if(this.modalData.description.includes("Saldo tidak cukup")){
      console.log('saldo tidak cukup')
      this.router.navigate(['deposit'],{skipLocationChange: false})
    }
  }

}
