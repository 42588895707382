import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalSuccessComponent } from '../views/modal-success/modal-success.component';
import { ModalInfoComponent } from '../views/modal-info/modal-info.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionService {

  constructor(
    private router:Router,
    public modal: MatDialog,) { 
  }

  dialogSuccess(message,isReload){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "success";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      description: message,
      confirmButtonText: "OK",
      reload:isReload
    };
    const modalDialog = this.modal.open(ModalSuccessComponent, dialogConfig);
  }

  dialogError(message){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "error";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      description: message,
      confirmButtonText: message.includes("Saldo tidak cukup") ? "Isi Deposit" : "OK",
    };
    const modalDialog = this.modal.open(ModalInfoComponent, dialogConfig);
  }

  refreshCurrentPage(){
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

}
