import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalOnboardingComponent } from '../modal-onboarding/modal-onboarding.component';
import { LoginService } from '../../../service/login.service';
import { GlobalFunctionService } from '../../../service/global-function.service';

@Component({
  selector: 'app-daftar',
  templateUrl: './daftar.component.html',
  styleUrls: ['../style-onboarding.scss']
})
export class DaftarComponent implements OnInit {

  namaLengkap : string = '';
  noTelepon : string = '';
  namaPerusahaan : string = '';
  emailPerusahaan : string = '';
  kota : string = '';
  alamat: string = '';

  isNamaLengkap : boolean = true;
  isNoTelepon : boolean = true;
  isFormatTelepon : boolean = true;
  isNamaPerusahaan : boolean = true;
  isEmailPerusahaan : boolean = true;
  isFormatEmail : boolean = true;
  isKota : boolean = true;
  isAlamat: boolean = true;

  constructor(
    public dialogInfo: MatDialog,
    private loginService : LoginService,
    private globalFunctionService : GlobalFunctionService) { 
  }

  ngOnInit(): void {
  }

  namaLengkapChanged()
  {
    this.namaLengkap == "" ? this.isNamaLengkap = false : this.isNamaLengkap = true
  }

  noTeleponChanged()
  {
    if(this.noTelepon != "")
    {
      this.isNoTelepon = true

      var isNumber = Number(this.noTelepon)
      console.log('isNumber',isNumber)
      !isNumber ? this.isFormatTelepon = false : this.isFormatTelepon = true
    }
    else
    {
      this.isNoTelepon = false
      this.isFormatTelepon = true
    }
  }

  namaPerusahaanChanged()
  {
    this.namaPerusahaan == "" ? this.isNamaPerusahaan = false : this.isNamaPerusahaan = true
  }

  emailPerusahaanChanged()
  {
    if(this.emailPerusahaan != "")
    {
      this.isEmailPerusahaan = true
      this.emailPerusahaan.includes("@") && this.emailPerusahaan.includes(".") ? this.isFormatEmail = true : this.isFormatEmail = false
    }
    else
    {
      this.isEmailPerusahaan = false
      this.isFormatEmail = true
    }
  }

  kotaChanged()
  {
    this.kota == "" ? this.isKota = false : this.isKota = true
  }

  alamatChanged()
  {
    this.alamat == "" ? this.isAlamat = false : this.isAlamat = true
  }

  daftar() {

    if(this.namaLengkap == "" ||this.noTelepon == "" || this.namaPerusahaan == "" || this.emailPerusahaan == "" || this.kota == "" || this.alamat == "" || !this.emailPerusahaan.includes("@") || !this.emailPerusahaan.includes(".") || !Number(this.noTelepon)){
      if(this.namaLengkap == "" ||this.noTelepon == "" || this.namaPerusahaan == "" || this.emailPerusahaan == "" || this.kota == "" || this.alamat == ""){
        this.namaLengkapChanged()
        this.noTeleponChanged()
        this.namaPerusahaanChanged()
        this.emailPerusahaanChanged()
        this.kotaChanged()
        this.alamatChanged()
      }
    }else{
      const data = {     
        fullName: this.namaLengkap,
        username: '',
        companyId: 0,
        companyName: this.namaPerusahaan,
        companyEmail: this.emailPerusahaan,
        phoneNumber: this.noTelepon,
        city: this.kota,
        address: this.alamat
      };
      console.log('param : ', data)
      this.loginService.Register(data).subscribe(res => {
        var status = res['status']
        if(status.toLowerCase().includes('success'))
        {
          //this.globalFunctionService.dialogSuccess('Sukses tambah lokasi kantor',false)
          //this.router.navigate(['master/lokasi-kantor'])

          this.namaLengkap = "";
          this.noTelepon = "";
          this.namaPerusahaan = "";
          this.emailPerusahaan = "";
          this.kota = "";
          this.alamat = "";

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = "modal-component";
          dialogConfig.height = "auto";
          dialogConfig.width = "auto";
          dialogConfig.data = {
            name: "info",
            description: "Password telah dikirimkan ke email yang Anda daftarkan. Segera cek email dan log in agar bisa mengakses aplikasi Workpres. Jika ingin mengubah Password, Anda bisa mengubahnya melalui menu Profil.",
            confirmButtonText: "OK",
          };
          const modalDialog = this.dialogInfo.open(ModalOnboardingComponent, dialogConfig);
        }
        else
        {
          var message = res['message']
          this.globalFunctionService.dialogError(message)
        }
      },
      (error) => {
        console.log('ERROR create : ', error)
        //this.globalFunctionService.errorDialog('Error Login',error.message)
      });
    }
  }

}
