import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LokasiService {

  constructor(
    private http: HttpClient, 
  ) { }

  TipeLokasi() {
    return this.http.get(environment.apiEndPoint +`/Location/locationtype`);
  }

  AllLokasi() {
    return this.http.get(environment.apiEndPoint +`/Location`);
  }

  PostLokasi(data) {
    return this.http.post(environment.apiEndPoint +`/Location`, data);
  }

  UpdateLokasi(id , data) {
    return this.http.put(environment.apiEndPoint +`/Location/${id}`, data);
  }

  LokasiById(id){
    return this.http.get(environment.apiEndPoint +`/Location/${id}`);
  }

  DeleteLokasi(id){
    return this.http.delete(environment.apiEndPoint +`/Location/${id}`);
  }

  SearchLokasi(data) {
    return this.http.post(environment.apiEndPoint +`/Location/Search`, data);
  }
}
