import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserTokenService {

  constructor(
    private http: HttpClient, 
  ) { }

  Employee()
  {
    return this.http.get(environment.apiEndPoint +`/UserToken/Employee`);
  }

  ChangePassword(data) {
    return this.http.post(environment.apiEndPoint +`/UserToken/ChangePassword`, data);
  }

  UploadFile(data) {
    return this.http.post(environment.apiEndPoint +`/UserToken/UploadFile`, data);
  }

  GetFile(fileName){
    return this.http.get(environment.apiEndPoint +`/UserToken/GetFile/${fileName}`, { responseType: 'blob'});
  }

  UploadFileMaster(data) {
    return this.http.post(environment.apiEndPoint +`/UserToken/UploadFileMaster`, data);
  }

  GetFileMaster(fileName){
    return this.http.get(environment.apiEndPoint +`/UserToken/GetFileMaster/${fileName}`, { responseType: 'blob'});
  }

}
