import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DivisiService } from '../../../service/divisi.service';
import { LokasiService } from '../../../service/lokasi.service';
import { LokasiKantorService } from '../../../service/lokasi-kantor.service';
import { KaryawanService } from '../../../service/karyawan.service';
import { ApprovalService } from '../../../service/approval.service';
import { Router } from '@angular/router';
import { ModalInfoComponent } from '../../modal-info/modal-info.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GlobalFunctionService } from '../../../service/global-function.service';

@Component({
  selector: 'app-master-modal',
  templateUrl: 'delete-modal.component.html',
  styleUrls: ['delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  constructor(
    private router:Router,
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private divisiService : DivisiService,
    private lokaisService : LokasiService,
    private lokasiKantorService : LokasiKantorService,
    private karyawanService : KaryawanService,
    private approvalService : ApprovalService,
    private globalFunctionService : GlobalFunctionService,
    public modalInfo: MatDialog
    ) {
      console.log(this.modalData);
    }

  ngOnInit(): void {
  }

  delete()
  {
    if(this.modalData.menu.toLowerCase().includes('divisi'))
    {
      this.divisiService.DeleteDivisi(this.modalData.dataId).subscribe(res => {
        var status = res['status']
        if(status.toLowerCase().includes('success'))
        {
          this.dialogRef.close();
          this.globalFunctionService.refreshCurrentPage()
          this.globalFunctionService.dialogSuccess('Sukses hapus divisi',false)
        }
        else
        {
          var message = res['message']
          this.globalFunctionService.dialogError(message)
        }
      });
    }
    else if(this.modalData.menu.toLowerCase().includes('lokasi'))
    {
      this.lokaisService.DeleteLokasi(this.modalData.dataId).subscribe(res => {
        var status = res['status']
        if(status.toLowerCase().includes('success'))
        {
          this.dialogRef.close();
          this.globalFunctionService.refreshCurrentPage()
          this.globalFunctionService.dialogSuccess('Sukses hapus lokasi',false)
        }
        else
        {
          var message = res['message']
          this.globalFunctionService.dialogError(message)
        }
      });
    }
    else if(this.modalData.menu.toLowerCase().includes('kantor'))
    {
      this.lokasiKantorService.DeleteLokasiKantor(this.modalData.dataId).subscribe(res => {
        var status = res['status']
        if(status.toLowerCase().includes('success'))
        {
          this.globalFunctionService.dialogSuccess('Sukses hapus lokasi kantor',true)
        }
        else
        {
          var message = res['message']
          this.globalFunctionService.dialogError(message)
        }
      });
    }
    else if(this.modalData.menu.toLowerCase().includes('karyawan'))
    {
      console.log('delete karyawan')
      this.karyawanService.DeleteKaryawan(this.modalData.dataId).subscribe(res => {
        var status = res['status']
        if(status.toLowerCase().includes('success'))
        {
          this.globalFunctionService.dialogSuccess('Sukses hapus karyawan',false)
          this.router.navigate(['master/karyawan'])
        }
        else
        {
          var message = res['message']
          this.globalFunctionService.dialogError(message)
        }
      });
    }
    else if(this.modalData.menu.toLowerCase().includes('approval'))
    {
      console.log('delete approval')
      this.approvalService.DeleteManualAttendance(this.modalData.dataId).subscribe(res => {
        //this.router.navigate(['absensi/approval'])
        var status = res['status']
        if(status.toLowerCase().includes('success'))
        {
          this.globalFunctionService.dialogSuccess('Sukses hapus absensi manual',true)
        }
        else
        {
          var message = res['message']
          this.globalFunctionService.dialogError(message)
        }
      });
    }
  }

}
