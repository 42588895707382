import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(
    private http: HttpClient,
  ) { }

  RiawayatManual(data) {
    return this.http.post(environment.apiEndPoint +`/Attendance/RiawayatManual`, data);
  }

  RiwayatKehadiranManual(data) {
    return this.http.post(environment.apiEndPoint +`/Attendance/RiawayatKehadiranManual`, data);
  } 

  ManualAttendanceById(id){
    return this.http.get(environment.apiEndPoint +`/Attendance/ManualAttendance/${id}`);
  }

  UpdateManualAttendanceApprove(data) {
    return this.http.put(environment.apiEndPoint +`/Attendance/ManualAttendanceApprove`, data);
  }

  DeleteManualAttendance(id){
    return this.http.delete(environment.apiEndPoint +`/Attendance/DeleteManualAttendance/${id}`);
  }
}
